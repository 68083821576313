import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';


import './index.css';

import Navbar from './Navbar';

// import Bio from './pages/Bio';
import Home from './pages/Home';
import Resume from './pages/Resume';
import Contact from './pages/Contact';

const theme = createTheme();


ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth='lg'>
            <BrowserRouter>
                <Navbar />
                <Routes>
                    <Route path='/' element={<Home />} />
                    {/* <Route path='bio' element={<Bio />} /> */}
                    <Route path='resume' element={<Resume />} />
                    <Route path='contact' element={<Contact />} />
                </Routes>
            </BrowserRouter>
        </Container>
    </ThemeProvider>

    , document.getElementById('root')
);
