import { Grid, Paper, Typography } from '@mui/material';

import whathaveidonetodeservethis from '../images/whathaveidonetodeservethis.png';


export default function Home() {

    return (

        <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
                <Typography variant="h4" component="h1" gutterBottom>Oh, hey!</Typography>
                <Typography paragraph>
                    If the domain name didn't give it away already, my name is Brandon Stewart and you've reached my very own special, personal website!
                    If you were looking for my resume and/or email address, well then you are in luck!
                </Typography>
                <Typography paragraph>
                    I am currently a software deveveloper located in Morgantown, WV.
                    My general programming interests can be boiled down to &quot;try something new and hopefully make something in the process.&quot;
                </Typography>
            </Grid>

            <Grid item xs={12} sm={4} maxWidth="100%">
                <Paper
                    component="img"
                    alt=""
                    title=""
                    src={whathaveidonetodeservethis}
                    sx={{ width: '100%', justifyContent: 'center' }}
                >
                </Paper>
            </Grid>
        </Grid>
    );
}