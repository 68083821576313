import { Grid, Paper, Typography } from '@mui/material';
import marco from '../images/marco2.png';

export default function Contact() {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
                <Typography variant="h4" component="h1" gutterBottom>Contact</Typography>

                <p>If you wish to contact me, feel free to email me at <a href="mailto:brandon |at| brandonstewart.com">brandon |at| brandonstewart.com</a>.</p>

                <p>My mailing address and phone number are not listed on this website.  If you need this information, please email me.</p>
            </Grid>

            <Grid item xs={12} sm={4} maxWidth="100%">
                <Paper
                    component="img"
                    alt="[barks internally for once]"
                    title="[barks internally for once]"
                    src={marco}
                    sx={{ width: '100%', justifyContent: 'center' }}
                >
                </Paper>
            </Grid>
        </Grid>
    );
}