import { Box, Typography } from '@mui/material';


export default function Resume() {
    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom>Resume</Typography>

            <Typography paragraph><a href="docs/resume.pdf">Download</a> as PDF document.</Typography>


            <Typography variant="h6" component="h2" gutterBottom>Brandon Stewart</Typography>

            <Typography variant="subtitle1" gutterBottom>Education:</Typography>

            <Typography paragraph>
                <strong>West Virginia University</strong> -- <strong>Morgantown, WV</strong><br />
                Class of 2012<br />
                <strong>M.S. Computer Science</strong><br />
                <strong>GPA</strong>: 3.72<br />
                <strong>Coursework</strong>: Advanced Analysis of Algorithms, Compiler Construction, Computational Complexity,
                Developing Portable Software, Distributed Systems, Empirical Methods in Software Engineering and Computer Science,
                Formal Specification of Language, Statistical Pattern Recognition, Software Performance Engineering, Theory of Operating Systems<br />
                <strong>Report:</strong> <a href="docs/portability_study.pdf">Portability Study of Android and iOS</a>
            </Typography>

            <Typography paragraph>
                <strong>Marshall University</strong> -- <strong>Huntington, WV</strong><br />
                Class of 2007<br />
                <strong>B.S. Computer &amp; Information Technology</strong><br />
                (Emphasis in Software Development)<br />
                <strong>Minor</strong>: Mathematics<br />
                <strong>GPA</strong>: 3.50 (Major: 3.67)<br />
                <strong>Coursework</strong>: Advanced Communications, Statistics, Calculus 1&amp;2,
                Linear Algebra w/Differential Equations, Advanced C++ Problem Solving, Algorithms,
                Data Structures, Network Protocols, Web Programming, Software Engineering 1&amp;2,
                E-Commerce, Computer Graphics w/OpenGL, Scripting Languages, Database Programming, Operating Systems
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom>Skills:</Typography>
            <ul className="resume-content-list">
                <li>Languages: C#, C++, Java, JavaScript, TypeScript, Objective C, Perl, PHP, Python</li>
                <li>Experience with ASP.NET, .NET Core, Xamarin, WPF, EF, Angular, jQuery, Dojo, Node.js</li>
                <li>Databases: SQL Server, MySQL, Oracle</li>
                <li>Mobile development experience with Android and iOS</li>
                <li>Operating Systems: Windows, GNU/Linux (Debian/Ubuntu), Mac OS X</li>
                <li>Development Environments: Visual Studio, Eclipse, Xcode</li>
                <li>Experienced with code versioning systems, mainly Subversion and Team Foundation</li>

            </ul>

            <Typography variant="h6" component="h2" gutterBottom>Experience:</Typography>
            <Typography paragraph>
                <strong>Senior Consultant</strong> -- <strong>October 2016 - Present</strong><br />
                <strong><a href="https://www.maximus.com/">Maximus</a></strong> -- Morgantown, WV<br />
                Currently a full-stack application developer in support of CDC NIOSH.
                Development has mostly involved .NET Core Web API services with SQL Server databases and modern Angular single page applications.
                Ongoing recent work has been in support of the World Trade Center Health Program.
            </Typography>
            <Typography paragraph>
                <strong>Programmer Analyst, Lead</strong> -- <strong>September 2015 - September 2016</strong><br />
                <strong><a href="https://www.caci.com/">CACI International Inc</a></strong> -- Morgantown, WV<br />
                Worked as an application developer in support of CDC NIOSH.
                Primary development was on a new WPF application with SQL Server database backend for the Western States Division.
            </Typography>
            <Typography paragraph>
                <strong>Information Technology Specialist</strong> -- <strong>June 2012 - September 2015</strong><br />
                <strong><a href="https://www.stoller.com">S.M. Stoller Corporation</a></strong> -- Morgantown, WV<br />
                Worked in support of the Department of Energy's Office of Legacy Management, primarily on development of
                environmental services applications.  Participated in both self-driven development as well as in agile team
                development projects.  Development typically involved C# programming with SQL Server or Oracle databases.<br /><br />
                Extensive work with Esri JavaScript API on public-facing application connecting ArcGIS Server map services
                with existing environmental database for reports and visualization.
            </Typography>
            <Typography paragraph>
                <strong>Graduate Teaching Assistant</strong> -- <strong>August 2010 - May 2012</strong><br />
                <strong><a href="https://www.wvu.edu">West Virginia University</a></strong> -- Morgantown, WV<br />
                Taught two sections per semester of <a href="https://cs101.wvu.edu">CS 101</a> for the
                <a href="https://www.lcsee.cemr.wvu.edu">Lane Department of Computer Science and Electrical Engineering</a>.
                Responsibilities included class lectures, assisting students during open lab hours, and grading assignments.
            </Typography>
            <Typography paragraph>
                <strong>Software Engineer</strong> -- <strong>July 2007 - August 2010</strong><br />
                <strong><a href="https://www.ultra.group">Ultra Electronics, ProLogic</a></strong> -- Morgantown, WV<br />
                Worked at the Department of Energy's <a href="https://www.netl.doe.gov">
                    National Energy Technology Laboratory
                </a> (NETL).  Worked on migration and retirement of legacy applications as
                well as ASP.NET with C# web applications with Oracle databases.<br /><br />

                Began working at the Department of Energy's <a href="https://www.lm.doe.gov">Office of Legacy Management</a> in October 2007.
                Work was primarily on web applications in ASP.NET with C# using SQL Server databases.  Responsibilities typically included
                the full development cycle from requirements gathering through deployment and user support.
            </Typography>
            <Typography paragraph>
                <strong>Teaching Assistant</strong> -- <strong>August 2005 - December 2005</strong><br />
                <strong><a href="https://www.marshall.edu">Marshall University</a></strong> -- Huntington, WV<br />
                Graded programming assignments for Professor Brian Morgan's IST 263: Data Structures class.<br />
            </Typography>
            <Typography paragraph>
                <strong>Intern</strong> -- <strong>Summer 2005 &amp; 2006</strong><br />
                <strong><a href="https://www.ultra.group">ProLogic, Incorporated</a></strong> -- Fairmont, WV<br />
                Worked with an existing team on a NASA contract in the summer of 2005, mainly on
                parts of the user interface with software that was in its early stages (UI was a plugin for Eclipse).
                Programming was primarily in Java.<br />
                <br />
                Worked on a Department of Defense contract in the summer of 2006, again mainly on
                parts of the design of the user interface (a plugin for ArcGIS). Gained much more experience with
                the software requirements and design processes. Programming was in Java/C#.  I also developed C#
                unit tests for classes which did not yet have tests written for them.
            </Typography>
            <Typography paragraph>
                <strong>Intern</strong> -- <strong>Summer 2003 &amp; 2004</strong><br />
                <strong><a href="https://www.nw3c.org">National White Collar Crime Center</a></strong> -- Fairmont, WV<br />
                Assisted with various tasks in the IT department including computer maintenance
                and network diagramming.
            </Typography>
            <Typography paragraph>
                <strong>Owner/Webmaster/Programmer</strong> --  <strong>1999 - 2006</strong><br />
                <strong>NPScripts.com</strong><br />
                Started releasing Perl scripts in September, 1999 in spare time while in school. Website became a commercial operation
                in March, 2002.  Main specialty was a cross-platform mailing list manager.  New products were released and existing products
                were updated for nearly six years.  Created documentation for all products.  Maintained website for product downloads,
                documentation, and private logins for paying users.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom>References</Typography>
            <Typography paragraph>References available upon request.</Typography>

        </Box>
    );
}